import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";

import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairBondingforMen = () => {

  const seoData = {
    title: 'Hair Bonding for Men in Delhi - Radiance Hair Studio',
    description: 'Discover the most innovative hair bonding for men in Delhi at Radiance Hair Studio. Experience a unique and advanced method for natural-looking results. ',
    keywords: ['hair bonding for men in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Hair-Bonding-for-Men's-copy.jpg",
      "https://www.radiancehairstudio.com/hair-bonding-for-men1.png",
      "https://www.radiancehairstudio.com/hair-bonding-for-men2.png",
      "https://www.radiancehairstudio.com/help-image-1.png",
      "https://www.radiancehairstudio.com/home/home-image-18.webp"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "disambiguatingDescription": "hair bonding for men in Delhi",
    "additionalType": "https://www.radiancehairstudio.com/hair-bonding-for-men.php",
    "category": "https://www.radiancehairstudio.com/hair-bonding-for-men.php",
    "description": "Discover the most innovative hair bonding for men in Delhi at Radiance Hair Studio. Experience a unique and advanced method for natural-looking results.",
    "mainEntityOfPage": "https://www.radiancehairstudio.com/hair-bonding-for-men.php",
    "serviceType": "Hair Bonding for Men's"
  };
  
  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Hair-Bonding-for-Men's-copy.jpg",
      content: (
        <div>
          <h2>Hair Bonding for Men's</h2>
          <p>
            Are you looking to adopt the latest hairstyle? There is no need to get
            through any treatment plans. The easiest way to do so is by going
            through hair bonding in Delhi. This will not only give you a better
            look, but you are going to be a style icon in your city.
          </p>
          <h2>Radiance Trends</h2>
          <p>
          <a href="/" style={{ color: "#373737"}}>Radiance</a> Hair Salon is ready to give you a wide range of style in this
            aspect. Try out a new looking every alternate day and start stunning
            your colleagues and friends. Hair bonding is going to open up a new
            horizon to your stylish life.
          </p>
          <p>
            Many do think that hair bonding is a hair loss treatment plan, but the
            reality is not on the same track. With
            <strong> hair bonding in Delhi,</strong> your natural hair will be
            trimmed and glue will be applied on the skull, where stylist hair will
            be adhered or taped by easy means. Are you getting worried about
            applying artificial hair on your skull? Please don’t be. Because here
            we provide our customers with 100% natural human hair extensions
            exclusively designed with individual care. Our hair experts will
            always be there to assist you with all solutions to your quires about
            hair problems and will guide you to the proper way you need to go.
          </p>
          <p>
            At Radiance, we will also be providing you with an excellent <a href="/gallery.php" style={{ color: "#373737"}}>gallery</a>
            of styles in the aspect of bonding. This will not only give you a
            stunning beauty but will also be a safe journey, as there is no
            side-effect from the entire thing. The glue we use is completely
            approved and certified by medical science. Our professionals are also
            perfectly trained to support you in the entire matter.
          </p>
          Before going for the magical transformation, you need to know about the
          benefits of hair bonding we have included below.
        </div>
      ),
    },
  ];
  
  const sectionS3 = [
    {
      smallImg: "/hair-bonding-for-men1.png",
      largeImg: "/hair-bonding-for-men2.png",
      content: (
        <div>
          <h2>Benefits of going through hair bonding in Delhi</h2>
  
          <ul>
            <li>
  
              The first benefit, in this case, must be declared for those, who are
              in look of a new style. You can change your style here on daily
              basis. The full process is free from all sorts of hassle too. So,
              why to make a delay?
            </li>
            <li>
  
              The process that is followed is using glue on your skull. Thus your
              natural hair will not be damaged by any means. In fact, for this
              particular reason, this is also a fancy choice of the Television
              stars.
            </li>
            <li>
  
              The adhesive that is applied to the skull is of international
              standard and that frees yours from all chances of side-effects.
              There is no risk in the full method.
            </li>
            <li>
  
              The method is fully an external one and hence is strain-less too.
              You can move out with the new look and can change the appearance on
              a daily basis, by applying the color of your choice.
            </li>
          </ul>
        </div>
      ),
    },
  ];
  
  const sectionS4 = [
    {
      title: "FAQs of Hair Bonding",
      description:
        "Here we tried to answer some relevant specific questions frequently asked by customers about hair bonding. You may find them relevant too",
      description2: "",
      faq: [
        {
          title: "Will I suffer any hair loss for the process?",
          description:
            "The first thing to be stated here is that it is an externally applied process. Here adhesive is applied on your skull that will not at all touch your natural hair. Hence, there remains no chance of hair loss.",
        },
        {
          title: "How quickly I can go for a change?",
          description:
            "You can even opt for a daily change in your looks. Style options you will get here is the different forms of patches and also in the form of hair colors. The flexibility of this type of hair is such that your daily style change will not also matter for it.",
        },
        {
          title: "Is there any side effect on the skull?",
          description:
            "The glue that is used in hair bonding in Delhi is of international standard and Radiance is having such good professionals in this matter that the least chance of side effects are also removed.",
        },
        {
          title:
            "I am having natural hair on my head. Can I still apply the method for my style?",
          description:
            "Definitely, you can. The binding is made from the skull and there is nothing that your natural hair has to do here. They will just be trimmed",
        },
      ],
    },
  ];
  

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Hair Bonding for Men's" banner="/hair-bonding-for-men-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Bonding for Men's" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairBondingforMen;
